.falling-passionfruit {
  position: fixed;
  width: 50px; /* Adjust the size of the passionfruit */
  animation: fall linear infinite;
  will-change: transform;
}

@keyframes fall {
  0% {
    top: -10%;
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    top: 110%;
    opacity: 0;
    transform: translateX(20vw);
  }
}

/* Add this inside your existing CSS for .container to prevent overflow issues */
.container {
  overflow: hidden;
  position: relative;
  height: 100vh; /* Adjust based on your content */
}

body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  text-align: center;
  padding: 20px;
  background-color: #6a0dad;
}

h1, p {
  color: #ffffff;
}

h2 {
  color: #ffd700;
  font-size: 24px;
}

.social-links a {
  color: #ffd700;
  text-decoration: none;
  font-size: 18px;
}

.listings img {
  width: 40px;
};

img {
  max-width: 100%;
  height: auto;
}